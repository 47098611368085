import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Commento from "react-commento"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ColorWrapper from "../components/ColorWrapper"
import ExternalLink from "../components/ExternalLink"
import ProjectLogo from "../components/ProjectLogo"

const Header = styled.header`
  margin-top: 1rem;
  margin-bottom: 2rem;
`

const Title = styled.h1`
  margin-bottom: 1rem;
  font-size: 3rem;
`

const Section = styled.section`
  line-height: 1.7rem;

  img {
    display: block;
    margin: auto;
  }
`

const LinkList = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
`

const CommentsWrapper = styled.div`
  margin-top: 1rem;
`

const ArticleHeader = ({ title, externalUrl, color, imageSrc, fixed }) => (
  <Header>
    <ProjectLogo
      fixed={fixed}
      alt={`Logo of ${title}`}
      imageSrc={imageSrc}
      height={200}
    />
    <Title>{title}</Title>
    <ColorWrapper color={color}>
      <ExternalLink href={externalUrl}>
        {externalUrl.split("://")[1]}
      </ExternalLink>
    </ColorWrapper>
  </Header>
)

const ProjectTemplate = ({ data, pageContext }) => {
  const project = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  const {
    frontmatter: {
      title,
      description,
      externalUrl,
      color,
      image: { publicURL: imageSrc, childImageSharp },
    },
  } = project
  const fixed = childImageSharp?.fixed

  return (
    <Layout title={siteTitle}>
      <SEO title={title} description={description || project.excerpt} />
      <article>
        <ArticleHeader
          title={title}
          externalUrl={externalUrl}
          color={color}
          imageSrc={imageSrc}
          fixed={fixed}
        />
        <Section dangerouslySetInnerHTML={{ __html: project.html }} />
      </article>

      <nav>
        <LinkList>
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                <ColorWrapper>← {previous.frontmatter.title}</ColorWrapper>
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                <ColorWrapper right>{next.frontmatter.title} →</ColorWrapper>
              </Link>
            )}
          </li>
        </LinkList>
      </nav>

      <CommentsWrapper>
        <Commento id={project.id} />
      </CommentsWrapper>
    </Layout>
  )
}

export default ProjectTemplate

export const pageQuery = graphql`
  query ProjectsBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        externalUrl
        color
        image {
          publicURL
          childImageSharp {
            fixed(height: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
